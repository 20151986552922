import './global/global.scss';
import { ChakraProvider, Box, theme } from '@chakra-ui/react';
import ScrollToTopButton from './components/ScrollToTopButton';
import Home from './views/home/Home';
export const App = () => (
  <ChakraProvider theme={theme}>
    <Box position="relative" className="App">
      <ScrollToTopButton />
      <Home />
    </Box>
  </ChakraProvider>
);
