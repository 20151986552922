import { Box, SimpleGrid, useBreakpointValue } from '@chakra-ui/react';
import React, { ReactNode, useEffect, useState } from 'react';
import { Lightbox } from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
interface PhotoGalleryProps {
  content: string[];
  open: boolean;
  tabId: number;
  selectedTabId: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const PhotoGallery: React.FC<PhotoGalleryProps> = ({
  content,
  open,
  setOpen,
  tabId,
  selectedTabId,
}) => {
  const [index, setIndex] = useState<number>(-1);
  const size =
    useBreakpointValue({ base: 1, sm: 2, lg: 3 }, { ssr: false }) || 1;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    open && tabId == selectedTabId && setIndex(0);
  }, [open]);

  const images: ReactNode[] = content.map((v, i) => (
    <Box
      key={i}
      bgImage={v}
      bgSize="cover"
      bgPos="center"
      onClick={() => setIndex(i)}
    />
  ));
  const photos = content.map((p) => ({
    src: p,
  }));

  return (
    <Box h="100%">
      <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} gap="4px" h="33%">
        {size == 1
          ? images.slice(0, 1)
          : size == 2
          ? images.slice(0, 2)
          : images.slice(0, 3)}
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 2, sm: 3, lg: 4 }}
        mt="4px"
        gap="4px"
        h="33%"
      >
        {size == 1
          ? images.slice(1, 3)
          : size == 2
          ? images.slice(2, 5)
          : images.slice(3, 7)}
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, sm: 2, lg: 3 }}
        mt="4px"
        gap="4px"
        h="33%"
      >
        {size == 1
          ? images.slice(3, 4)
          : size == 2
          ? images.slice(5, 7)
          : images.slice(7, 10)}
      </SimpleGrid>
      <Lightbox
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => {
          setOpen(false);
          setIndex(-1);
        }}
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </Box>
  );
};
