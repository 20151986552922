import { Box, Heading, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import Deco from '../../assets/svgs/Deco';
import Section from '../../components/section/Section';
import { GalleryTabs } from '../../components/tabs/GalleryTabs';

const Gallery: React.FC = () => (
  <Section id="Galeria" minHeight="100vh">
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      h="100px"
      mt="1rem"
      py={{ base: '35px', sm: '40px', md: '60px' }}
      w="100%"
    >
      <Deco
        width={useBreakpointValue({
          base: '180px',
          sm: '200px',
          md: '300px',
        })}
      />
    </Box>
    <Box h="fit-content" mb={0} w="100%">
      <Heading
        pb={{ base: '30px', sm: '40px' }}
        fontFamily="EB Garamond"
        color="#c29a5c"
        textAlign="center"
        letterSpacing="1px"
        fontSize={{ base: '2.5rem', sm: '3rem', lg: '4rem' }}
      >
        Galería
      </Heading>

      <GalleryTabs />
    </Box>
    <Box
      display="flex"
      justifyContent="center"
      alignItems="end"
      h="100px"
      pb="50px"
    >
      <Deco
        width={useBreakpointValue({
          base: '180px',
          sm: '200px',
          md: '300px',
        })}
      />
    </Box>
  </Section>
);
export default Gallery;
