import { Box, Heading, Link } from '@chakra-ui/react';
import Logo from '../../assets/svgs/RioGrandeLogo';
import Section from '../../components/section/Section';

const Hero: React.FC = () => (
  <Section
    minHeight={{ base: '100vh', sm: '100vh', md: '100vh' }}
    id="Inicio"
    pt="60px"
  >
    <Box position="relative" h="100%" width="full" overflow="hidden">
      <Box
        height={{ base: '100vh', sm: '100vh', md: '100vh' }}
        position="relative"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundImage={`url(https://res.cloudinary.com/dgwdvgu1g/image/upload/v1684625839/rioGrandeHosteria/portada2_f035qa.jpg)`}
      >
        <Box bg="#0005" height="100%">
          <Box
            height="100%"
            bg={{ base: '#0006', md: '#ffffff' }}
            clipPath={{
              base: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
              md: 'polygon(0% 0%, 55% 0%, 45% 100%, 0% 100%)',
            }}
          >
            <Box
              w={{ base: '100%', md: '50%' }}
              h="100%"
              p={{ base: '2rem', md: '3rem', xl: '4rem' }}
              display="flex"
              flexDirection="column"
              alignItems={{ base: 'start', sm: 'center' }}
              justifyContent="center"
            >
              <Box
                px={{ md: '1rem' }}
                mt="-40px"
                mb={{ base: '20px', md: '20px', xl: '40px' }}
                w={{ base: '300px', md: '400px', xl: '500px' }}
              >
                <Logo width={'max-content'}></Logo>
              </Box>
              <Heading
                fontFamily="EB Garamond"
                color={{ base: '#aaa', md: '#444' }}
                my="1rem"
                fontWeight={500}
              >
                Sol, Montaña y Rio
              </Heading>
              <Heading
                size={'lg'}
                fontWeight={500}
                mb="1rem"
                fontFamily="EB Garamond"
                color={{ base: '#aaa', md: '#444' }}
                textAlign={{ base: 'left', md: 'center' }}
              >
                Un Lugar Para Disfrutar En Familia
              </Heading>
              <Link
                href="/#Contacto"
                textDecoration="none"
                mt={{ base: '4rem', md: '5rem', xl: '6rem' }}
                borderRadius="0px"
                border="1px solid #c29a5c"
                bg="transparent"
                fontFamily="EB Garamond"
                fontSize="1.3rem"
                fontWeight="600"
                color="#c29a5c"
                px="1.5rem"
                py="0.3rem"
                textAlign="center"
                w="50%"
                _hover={{
                  color: '#fff',
                  bg: '#c29a5c',
                }}
                _focus={{
                  outline: 'none',
                }}
                _active={{
                  bg: '#c29a5c',
                }}
              >
                Saber Mas...
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  </Section>
);

export default Hero;
