import { Box, Text } from '@chakra-ui/react';
import React from 'react';
// import { MdLocationPin } from 'react-icons/md';
import { MdLocationOn } from 'react-icons/md';
import Deco from '../../assets/svgs/Deco';
const ContactInfo: React.FC = () => (
  <Box
    h="100%"
    w="100%"
    maxW="600px"
    minW="350px"
    mt={{ base: '1.5rem', lg: '0' }}
    display="flex"
    flexDirection="column"
    alignItems="center"
  >
    <Text as="span" fontSize="2.5rem" color="#c29a5c" mb="1rem">
      <MdLocationOn />
    </Text>

    <Text
      as="h6"
      fontFamily="EB Garamond"
      fontSize="1rem"
      fontWeight="700"
      color="#1a1a1a"
      letterSpacing="2px"
      mb="0.25rem"
    >
      DIRECCION
    </Text>
    <Text
      fontFamily="EB Garamond"
      fontSize={{ base: '1.35rem', sm: '1.7rem', md: '2rem' }}
      fontWeight="700"
      color="#1a1a1a"
      textAlign="center"
      mb="1.5rem"
    >
      Rio Grande 500, San Antonio de Arredondo, Córdoba, Argentina
    </Text>
    <Text
      as="h4"
      fontFamily="EB Garamond"
      fontSize={{ base: '1.35rem', sm: '1.7rem', md: '2rem' }}
      fontWeight="700"
      color="#1a1a1a"
      mb="1.5rem"
    >
      +54-3516-666-222
    </Text>
    <Text
      as="h4"
      fontFamily="EB Garamond"
      fontSize={{ base: '1.35rem', sm: '1.7rem', md: '2rem' }}
      fontWeight="700"
      color="#1a1a1a"
      mb="1.5rem"
    >
      Riograndehosteria@gmail.com
    </Text>
    <Text as="span" color="#c29a5c" mb="1.5rem" py="1rem">
      <Deco width="170px" />
    </Text>
  </Box>
);
export default ContactInfo;
