import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import Section from '../../components/section/Section';
import Map from '../../components/Map';
import ContactInfo from '../../components/contactInfo/ContactInfo';
import ContactForm from '../../components/forms/ContactForm';

const Contact: React.FC = () => (
  <Section id="Contacto" minHeight="100vh">
    <Box mb={{ base: '2rem', lg: '0' }}>
      <Heading
        py={{ base: '35px', sm: '50px', md: '60px' }}
        bg="#c29a5c"
        fontFamily="EB Garamond"
        color="#fff"
        textAlign="center"
        letterSpacing="1px"
        fontSize={{ base: '2.5rem', sm: '3rem', xl: '4rem' }}
      >
        Contáctanos
      </Heading>
    </Box>

    <Box
      display="flex"
      justifyContent="start"
      alignItems="center"
      flexDirection={{
        base: 'column',
        lg: 'row-reverse',
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        h="100%"
        width={{ base: '100%', lg: '50%' }}
      >
        <ContactInfo />
      </Box>
      <Box
        px="1rem"
        py="3rem"
        w={{ base: '100%', lg: '50%' }}
        h="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <ContactForm />
      </Box>
    </Box>
    <Box>
      <Map />
    </Box>
  </Section>
);
export default Contact;
