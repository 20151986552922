import React from 'react';
import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import Logo from '../../assets/svgs/RioGrandeLogo';

interface Props {
  children: React.ReactNode;
  label: string;
  href: string;
}

const SocialButton: React.FC<Props> = ({ children, label, href }) => (
  <chakra.button
    bg="blackAlpha.100"
    rounded="full"
    w={10}
    h={10}
    cursor="pointer"
    as="a"
    fontSize="1.5rem"
    href={href}
    target="_blank"
    display="inline-flex"
    alignItems="center"
    justifyContent="center"
    transition="all 0.3s ease"
    _hover={{
      color: '#ba801b',
      bg: '#4A4A4A',
      transform: 'scale(1.2, 1.2)',
      zIndex: 1,
    }}
  >
    <VisuallyHidden>{label}</VisuallyHidden>
    {children}
  </chakra.button>
);

const Footer = () => (
  <Box bg="#2A2A2A" color="#F4F4F4">
    <Container
      as={Stack}
      maxW="6xl"
      py={4}
      direction={{ base: 'column', md: 'row' }}
      spacing={4}
      justify={{ base: 'center', md: 'space-between' }}
      align={{ base: 'center', md: 'center' }}
    >
      <Logo height="60px" />
      <Text>© 2022 Rio Grande. All rights reserved</Text>
      <Stack direction="row" spacing={6}>
        <SocialButton label="Twitter" href="https://wa.me/5493516666222">
          <FaWhatsapp />
        </SocialButton>
        <SocialButton label="YouTube" href="https://fb.me/riograndehosteria">
          <FaFacebook />
        </SocialButton>
        <SocialButton
          label="Instagram"
          href="https://www.instagram.com/riograndehosteria/"
        >
          <FaInstagram />
        </SocialButton>
      </Stack>
    </Container>
  </Box>
);
export default Footer;
