import React from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react';
interface Props {
  label: string;
  type: string;
}
const FormInput: React.FC<Props> = ({ label, type }) => (
  <FormControl pb="0.7rem">
    <FormLabel
      fontFamily="EB Garamond"
      fontSize="1.25rem"
      fontWeight="600"
      color="#5a5a5a"
    >
      {label}
    </FormLabel>
    <Input
      type={type}
      borderRadius="0px"
      _focus={{
        borderColor: '#c29a5c',
      }}
    />
  </FormControl>
);

const ContactForm = () => (
  <Box
    p="1.5rem"
    // boxShadow="-2px 3px 10px 3px #0005"
    borderRadius="5px"
    w="100%"
    h="100%"
    maxW="650px"
    minW="350px"
  >
    <FormInput label="Nombre" type="text" />
    <FormInput label="Email" type="email" />

    <Stack
      display="flex"
      flexDirection={{ base: 'column', md: 'row' }}
      w="100%"
      gap={{ base: '0', md: '1rem' }}
      spacing="none"
    >
      <FormInput label="Cantidad de personas" type="number" />
      <FormInput label="Telefono" type="tel" />
    </Stack>
    <Stack
      display="flex"
      flexDirection={{ base: 'column', md: 'row' }}
      w="100%"
      gap={{ base: '0', md: '1rem' }}
      spacing="none"
    >
      <FormInput label="Fecha de entrada" type="date" />
      <FormInput label="Fecha de Salida" type="date" />
    </Stack>
    <FormControl pb="0.7rem">
      <FormLabel
        fontFamily="EB Garamond"
        fontSize="1.25rem"
        fontWeight="600"
        color="#5a5a5a"
      >
        Mensaje
      </FormLabel>
      <Textarea
        borderRadius="0px"
        _focus={{
          borderColor: '#c29a5c',
        }}
      />
    </FormControl>
    <FormControl pb="0.7rem" textAlign="center">
      <Button
        borderRadius="0px"
        border="1px solid #c29a5c"
        bg="transparent"
        mt="0.5rem"
        fontFamily="EB Garamond"
        fontSize="1.3rem"
        fontWeight="600"
        color="#c29a5c"
        px="2rem"
        w="100%"
        _hover={{
          color: '#fff',
          bg: '#c29a5c',
        }}
        _focus={{
          outline: 'none',
        }}
        _active={{
          bg: '#c29a5c',
        }}
      >
        Enviar
      </Button>
    </FormControl>
  </Box>
);
export default ContactForm;
