import React from 'react';
interface Props {
  width?: string | number;
  height?: string | number;
}

const Deco: React.FC<Props> = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 200 18"
    fill="none"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M98.7483 6.49796C99.2208 7.09206 100.208 7.23056 100.767 6.6765C102.223 5.21282 103.273 3.1642 106.366 2.35939C105.882 0.80266 103.531 0.114914 101.798 1.8284C101.403 0.255533 100.742 -0.123679 99.382 0.0322848C98.5011 0.141572 98.0943 1.07888 97.9691 1.8407C96.4754 0.336343 94.0246 0.516947 93.3395 2.36707C95.8275 2.99259 97.1162 4.53324 98.7483 6.49796Z"
        fill="#C29A5C"
      />
      <path
        d="M82.4797 6.97206C82.6299 7.12291 82.9506 7.18601 83.021 6.92435C83.6218 5.76238 83.9739 4.42334 83.8722 3.11823C83.7204 1.85617 82.1761 1.34677 81.0981 1.80389C80.2094 2.23484 79.8808 3.35373 80.2438 4.23408C80.7132 5.3268 81.5628 6.22102 82.4797 6.97206Z"
        fill="#C29A5C"
      />
      <path
        d="M116.926 7.10139C117.212 7.14757 117.39 6.88901 117.594 6.73969C121.308 3.69626 119.434 1.03184 117.081 1.77153C116.55 1.93929 116.139 2.3764 115.997 2.90429C115.718 3.68308 115.987 4.49722 116.153 5.26834C116.331 5.904 116.535 6.55962 116.926 7.10139Z"
        fill="#C29A5C"
      />
      <path
        d="M98.7685 10.0948C98.1364 10.1055 97.5012 10.0624 96.8706 10.1086C96.6555 10.2402 94.811 12.9771 91.6871 13.1745C88.8614 13.3387 85.5322 10.9206 85.7495 8.19713C86.1546 2.72946 93.3432 3.07871 93.1688 6.80733C93.1328 7.63845 92.3709 8.33411 91.526 8.30642C91.5917 7.89396 91.6652 7.44761 91.4462 7.06437C91.0018 6.23482 89.6563 6.15785 89.0836 6.89045C88.4833 7.57707 88.469 9.15342 90.9471 9.84702C92.5696 10.2303 94.3423 9.09903 94.7115 7.51534C95.0228 5.99933 94.408 4.32175 93.1282 3.40446C89.8073 1.13898 83.8065 2.93731 83.5388 8.87435C83.2008 8.5219 82.8519 8.18331 82.4858 7.86317C80.7397 6.34101 78.8997 4.70342 76.5326 4.2525C75.0869 4.11862 73.5238 4.15093 72.2503 4.92662C70.495 6.15623 69.6407 8.31227 70.6434 10.4227C71.6057 12.3173 74.3687 13.013 76.1524 11.8525C77.1255 11.2507 77.4885 9.93629 77.0536 8.91129C76.6546 8.06325 75.5766 7.76623 74.7114 8.00015C74.0167 8.25409 73.5379 9.05137 73.7476 9.7701C73.8133 10.1841 74.0981 10.5073 74.364 10.8182C73.6662 10.7628 72.8776 10.6843 72.4005 10.1133C71.4054 9.00361 71.8529 7.12595 73.0733 6.35793C74.1075 5.62996 75.4656 5.76846 76.6515 5.947C80.6328 7.0743 81.3922 11.2515 86.3128 13.8194C88.4923 14.8475 91.0473 15.063 93.3848 14.4766C95.7238 13.8609 97.5997 12.1356 98.7685 10.0948Z"
        fill="#C29A5C"
      />
      <path
        d="M123.147 4.28924C120.355 4.91103 118.282 6.9965 116.286 8.87264C116.184 6.48705 114.887 4.06146 112.645 2.99947C110.911 2.3069 108.852 2.2438 107.174 3.12105C105.537 3.9568 104.706 6.00528 105.213 7.72905C105.653 9.02802 107.023 10.0438 108.443 9.9007C110.178 9.79736 111.731 8.3899 110.896 7.04263C110.422 6.2423 109.068 6.13764 108.521 6.9118C108.136 7.2858 108.242 7.84601 108.325 8.31391C107.601 8.29236 106.887 7.8168 106.74 7.09497C106.299 4.21502 110.18 3.1121 112.531 4.94177C114.148 6.30132 115.065 9.62472 112.429 11.6614C109.644 14.0505 105.789 13.6962 103.043 10.0931C102.4 10.09 101.755 10.0931 101.112 10.0931C102.281 12.2077 104.31 13.9131 106.696 14.5271C111.578 15.6145 115.184 13.5958 118.337 9.78983C119.346 8.44621 120.582 7.24267 122.053 6.39305C123.036 5.82822 124.201 5.76664 125.312 5.83284C127.995 6.09986 128.776 9.04208 127.187 10.3439C126.723 10.7548 126.069 10.7564 125.486 10.8133C126.104 10.2377 126.382 9.20651 125.824 8.50469C125.298 7.76284 124.178 7.79978 123.453 8.20919C122.349 8.96644 122.425 10.6856 123.355 11.549C124.323 12.4771 125.852 12.6464 127.101 12.2385C129 11.5891 130.106 9.33891 129.499 7.46429C128.661 4.62881 126.096 3.89955 123.147 4.28924Z"
        fill="#C29A5C"
      />
      <path
        d="M12.8438 7.47213C11.5624 7.3413 10.1746 7.15357 9.1529 6.31015C8.12495 5.44829 7.39585 4.18932 7.4522 2.82874C6.41175 3.00727 5.4542 3.65213 4.9645 4.57867C4.59525 5.38052 4.8816 6.27164 5.2555 7.01501C3.4265 6.97345 1.2736 7.07812 0 8.56639C1.15425 9.62311 2.17955 10.1933 5.2821 10.087C4.8268 10.915 4.5217 12.014 5.14285 12.842C5.63255 13.6546 6.5588 13.9978 7.40215 14.338C7.61895 13.4365 7.2831 12.7771 8.85565 11.0551C9.64265 10.2379 10.7989 9.94852 11.8863 9.74996C12.9706 9.53448 14.0815 9.59911 15.1798 9.59144C32.1698 9.58991 49.1614 9.59296 66.1514 9.58991C66.1498 8.885 66.1498 8.18014 66.1498 7.47523C48.3822 7.47371 30.613 7.48138 12.8438 7.47213Z"
        fill="#C29A5C"
      />
      <path
        d="M200 8.5941C198.761 7.07194 196.58 6.9719 194.754 7.01498C195.091 6.29005 195.363 5.44821 195.044 4.6679C194.634 3.65672 193.581 3.02878 192.534 2.81641C192.529 3.30274 192.554 3.80452 192.374 4.26774C191.867 5.47748 190.921 6.56559 189.632 6.99344C187.37 7.65507 192.456 7.48548 133.861 7.47515C133.858 8.18006 133.86 8.8865 133.861 9.59141C151.413 9.59141 168.965 9.58989 186.517 9.59141C187.756 9.6345 189.012 9.8423 190.153 10.3348C191.124 10.7719 191.763 11.6645 192.248 12.5649C192.556 13.102 192.515 13.7331 192.567 14.3271C193.574 14.0239 194.602 13.4575 195.044 12.4633C195.371 11.6768 195.089 10.8134 194.734 10.09C196.582 10.1948 198.652 9.99005 200 8.5941Z"
        fill="#C29A5C"
      />
      <path
        d="M67.1308 9.55309C67.7426 9.55156 68.3544 9.55156 68.9661 9.55309C68.9661 8.86052 68.9661 8.16791 68.9677 7.47687C68.3544 7.47534 67.7425 7.47534 67.1308 7.47534C67.1277 8.16791 67.1277 8.86052 67.1308 9.55309Z"
        fill="#C29A5C"
      />
      <path
        d="M131.006 9.55309C131.616 9.55156 132.228 9.55156 132.841 9.55309C132.842 8.86052 132.842 8.16791 132.841 7.47534C132.228 7.47534 131.616 7.47534 131.006 7.47534C131.004 8.16791 131.004 8.86052 131.006 9.55309Z"
        fill="#C29A5C"
      />
      <path
        d="M96.3874 7.6277C96.0901 7.6308 95.7928 7.62771 95.5018 7.68309C95.0277 7.75234 94.7289 8.22637 94.6992 8.66657C94.6366 9.30375 95.2906 9.82855 95.9086 9.78546C98.6279 9.77622 101.349 9.79314 104.068 9.77779C104.871 9.73623 105.238 8.69274 104.816 8.08172C104.526 7.45099 104.231 7.65284 96.3874 7.6277Z"
        fill="#C29A5C"
      />
      <path
        d="M100.753 11.303C100.286 10.8212 99.3976 10.8505 98.9329 11.3199C97.7531 12.4276 96.4414 14.866 93.3333 15.6324C94.1024 17.5631 96.7607 17.5948 97.9645 16.0772C98.1464 17.4857 99.0773 18.3221 100.591 17.8825C101.341 17.587 101.658 16.8052 101.781 16.0756C102.319 16.6682 103.059 17.1776 103.901 17.1407C104.907 17.2007 106.104 16.6497 106.349 15.6155C103.278 14.9215 102.027 12.4315 100.753 11.303Z"
        fill="#C29A5C"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="200" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Deco;
