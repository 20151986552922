import React from 'react';
import { Box, HTMLChakraProps } from '@chakra-ui/react';

interface SectionProps {
  children?: React.ReactNode;
}
const Section: React.FC<SectionProps & HTMLChakraProps<'div'>> = ({
  children,
  ...props
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Box bg="#fff" width="100%" minHeight="100vh" {...props}>
    {children}
  </Box>
);

export default Section;
