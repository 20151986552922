import React from 'react';

const Map: React.FC = () => (
  <iframe
    title="Rio Grande"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4046.6656454295703!2d-64.52719575575762!3d-31.473954846855456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x942d67fd355901ed%3A0x57765abb94163274!2sHoster%C3%ADa%20Rio%20Gande!5e0!3m2!1ses-419!2sar!4v1646952711416!5m2!1ses-419!2sar"
    width="100%"
    height="450"
    style={{ border: '0px' }}
    // allowFullScreen=""
    loading="lazy"
  />
);

export default Map;
