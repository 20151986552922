import React from 'react';
import { Box, Heading, Img, Stack, Text } from '@chakra-ui/react';
import {
  FaBed,
  FaCar,
  FaCoffee,
  FaMap,
  FaMountain,
  FaSwimmingPool,
  FaTree,
  FaWifi,
  FaSnowflake,
  FaWater,
} from 'react-icons/fa';
import rio from '../../assets/img/rio.jpg';
import entrada from '../../assets/img/entrada.jpg';
import pileta from '../../assets/img/pileta.jpg';
import Section from '../../components/section/Section';

const About: React.FC = () => (
  <Section id="Nosotros" minHeight="100vh" bg="#f3eee8">
    <Box
      display="flex"
      justifyContent="start"
      alignItems="center"
      flexDirection="column"
      h="100%"
    >
      <Box width="full">
        <Stack
          display="flex"
          flexDirection={{ base: 'column', md: 'row' }}
          height={{ base: 'fit-content', md: '500px' }}
        >
          <Box mt="0px" width={{ base: '100%', md: '50%' }}>
            <Img src={entrada} alt="foto" objectFit="cover" h="100%" w="100%" />
          </Box>

          <Box
            p="3rem"
            width={{ base: '100%', md: '50%' }}
            style={{ margin: '0' }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap="1rem"
          >
            <Heading
              pb="1rem"
              textAlign="center"
              fontFamily="EB Garamond"
              color="#c29a5c"
            >
              Sobre Nosotros
            </Heading>
            <Text fontFamily="Open Sans" color="#5a5a5a" fontSize="1rem">
              Hostería Río Grande es un acogedor complejo turístico ideal para
              pasar buenos momentos en familia y pareja. Se encuentra ubicado en
              San Antonio de Arredondo a tan solo diez minutos de Carlos Paz y a
              una cuadra del hermoso río que caracteriza a esta zona y que
              incita a salir a conocer el lugar y explorar la naturaleza.
            </Text>
            <Text fontFamily="Open Sans" color="#5a5a5a" fontSize="1rem">
              El establecimiento se encuentra atendido por sus dueños Omar y
              Silvia, que se encargan de brindar a los turistas una experiencia
              personalizada para que disfruten de una estancia agradable e
              inolvidable.
            </Text>
          </Box>
        </Stack>
        <Stack
          display="flex"
          flexDirection={{ base: 'column', md: 'row' }}
          height={{ base: 'fit-content', md: '600px', lg: '500px' }}
        >
          <Box
            p="3rem"
            width={{ base: '100%', md: '50%' }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap="1rem"
          >
            <Heading
              pb="1rem"
              textAlign="center"
              fontFamily="EB Garamond"
              color="#c29a5c"
            >
              El Complejo
            </Heading>
            <Text fontFamily="Open Sans" color="#5a5a5a" fontSize="1rem">
              Rio Grande cuenta con lugares recreativos para merendar y
              compartir momentos con los demás visitantes. Mesas de tejo y ping
              pong para divertirse a más no poder y como no puede faltar una
              espectacular pileta con un excelente lugar para tomar sol. Además
              dispone de un parque de juegos para niños y bicicletas disponibles
              para pasear por el pueblo.
            </Text>
            <Text fontFamily="Open Sans" color="#5a5a5a" fontSize="1rem">
              Dispone de cabañas y habitaciones con baño privado, cochera
              techada, terraza y un salón de usos múltiples está equipado con un
              living ideal para ver películas en familia, mesas para compartir
              cenas entre amigos y barra desayunador. El lugar tambien cuenta
              con parrilla, asador y horno de barro para hacer exquisitas
              comidas.
            </Text>
          </Box>
          <Box width={{ base: '100%', md: '50%' }} style={{ margin: '0' }}>
            <Img
              src={rio}
              alt="foto de rio"
              objectFit="cover"
              h="100%"
              w="100%"
            />
          </Box>
        </Stack>
        <Stack
          display="flex"
          flexDirection={{ base: 'column', md: 'row' }}
          height={{ base: 'fit-content', md: '600px' }}
        >
          <Box width={{ base: '100%', md: '50%' }}>
            <Img src={pileta} alt="foto" objectFit="cover" h="100%" w="100%" />
          </Box>
          <Box
            p="3rem"
            width={{ base: '100%', md: '50%' }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap="1rem"
            style={{ margin: '0' }}
          >
            <Heading
              pb="1rem"
              textAlign="center"
              fontFamily="EB Garamond"
              color="#c29a5c"
            >
              Te Ofrecemos
            </Heading>
            <Box
              display="flex"
              flexDirection={{ base: 'column', sm: 'row' }}
              w="100%"
              gap="1rem"
              color="#5a5a5a"
              fontSize={{ base: '1.2rem', md: '1rem', lg: '1.2rem' }}
            >
              <Stack width={{ base: '100%', sm: '50%' }} gap="1rem">
                <Box display="flex" alignItems="center" gap="1rem">
                  <FaWater color="#c29a5c" fontSize="2rem" />
                  <Text>Rio a Menos de 100 Metros</Text>
                </Box>
                <Box display="flex" alignItems="center" gap="1rem">
                  <FaMountain color="#c29a5c" fontSize="2rem" />
                  <Text> Vista a las Montañas</Text>
                </Box>
                <Box display="flex" alignItems="center" gap="1rem">
                  <FaTree color="#c29a5c" fontSize="2rem" />
                  <Text> Naturaleza y Tranquilidad</Text>
                </Box>
                <Box display="flex" alignItems="center" gap="1rem">
                  <FaMap color="#c29a5c" fontSize="2rem" />
                  <Text>A 10Km de Carlos Paz</Text>
                </Box>
                <Box display="flex" alignItems="center" gap="1rem">
                  <FaCar color="#c29a5c" fontSize="2rem" />
                  <Text> Asadores y Cocheras</Text>
                </Box>
              </Stack>
              <Stack w="50%" gap="1rem" width={{ base: '100%', sm: '50%' }}>
                <Box display="flex" alignItems="center" gap="1rem">
                  <FaBed color="#c29a5c" fontSize="2rem" />
                  <Text>Ropa Blanca</Text>
                </Box>
                <Box display="flex" alignItems="center" gap="1rem">
                  <FaCoffee color="#c29a5c" fontSize="2rem" />
                  <Text>Desayuno</Text>
                </Box>
                <Box display="flex" alignItems="center" gap="1rem">
                  <FaSwimmingPool color="#c29a5c" fontSize="2rem" />
                  <Text>Pileta con Solarium</Text>
                </Box>

                <Box display="flex" alignItems="center" gap="1rem">
                  <FaWifi color="#c29a5c" fontSize="2rem" />
                  <Text> WiFi </Text>
                </Box>
                <Box display="flex" alignItems="center" gap="1rem">
                  <FaSnowflake color="#c29a5c" fontSize="2rem" />
                  <Text> Aire Acondicionado</Text>
                </Box>
              </Stack>
            </Box>
            <Text
              pt="1rem"
              textAlign="center"
              fontFamily="EB Garamond"
              color="#c29a5c"
              fontSize="1.5rem"
            >
              Y Mucho Más...
            </Text>
          </Box>
        </Stack>
      </Box>
    </Box>
  </Section>
);
export default About;
