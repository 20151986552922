/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useState } from 'react';
import {
  Box,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { PhotoGallery } from './photoGallery/PhotoGallery';

export const GalleryTabs: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedTabId, setSelectedTabId] = useState<number>(1);
  const theComplex: string[] = [
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888314/rioGrandeHosteria/complejo/c2z6x8zfw8jbbrjnvbqa.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888339/rioGrandeHosteria/complejo/c70jgqroawzxgr8z8mot.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888271/rioGrandeHosteria/complejo/bvcuk6er7xkknewbmkog.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888242/rioGrandeHosteria/complejo/u37jegsq4us07dw7usk6.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888227/rioGrandeHosteria/complejo/rrf9kf2wu2whgngl9zaa.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888194/rioGrandeHosteria/complejo/qzqkzhwxx2bl2eiutlcu.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888182/rioGrandeHosteria/complejo/fymjtw7gnjgdi8ghm8cd.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360716/rioGrandeHosteria/area_comun/DSC02956_jpifpy.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888383/rioGrandeHosteria/complejo/qjwxoic5nghkuz1f8u3e.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684361056/rioGrandeHosteria/pileta/DSC02974_vyzjpm.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888217/rioGrandeHosteria/complejo/rknihelsctrzhxhfcth0.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888438/rioGrandeHosteria/complejo/lthctlrf1odnplkrzmff.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888088/rioGrandeHosteria/complejo/npne21xwz0qij1r0gylc.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888147/rioGrandeHosteria/complejo/uhuzecqewlmngkavlsfl.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360732/rioGrandeHosteria/area_comun/DSC02992_sewltz.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684361136/rioGrandeHosteria/DSC03006_ytef05.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684625793/rioGrandeHosteria/pileta/rio_hpkorx.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684361136/rioGrandeHosteria/DSC03005_qcuyp7.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684361121/rioGrandeHosteria/DSC02994_yzairb.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360650/rioGrandeHosteria/area_comun/DSC00302_zkwqdn.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360670/rioGrandeHosteria/area_comun/DSC00307_i7hrz1.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888159/rioGrandeHosteria/complejo/upjroyuf9vluucz7rgov.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888391/rioGrandeHosteria/complejo/qlnv4thketemgtr4fw6c.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888286/rioGrandeHosteria/complejo/h6lxtwwh0xro5hmbceyd.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684361137/rioGrandeHosteria/DSC02998-2_ffv9ht.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888297/rioGrandeHosteria/complejo/wcei3vctknij7jjhfgwv.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684625839/rioGrandeHosteria/portada2_f035qa.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684625829/rioGrandeHosteria/entrada_cl2afp.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360642/rioGrandeHosteria/area_comun/DSC00300_jjxyws.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684361114/rioGrandeHosteria/DSC00210-2_nwosxg.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360698/rioGrandeHosteria/area_comun/DSC00321_bg49wy.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360664/rioGrandeHosteria/area_comun/DSC00306_b3sk3g.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360730/rioGrandeHosteria/area_comun/DSC03004_ewg3zo.jpg',
  ];
  const bedrooms: string[] = [
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888012/rioGrandeHosteria/habitaciones/xl6cbr8fnmhdyezx2ljw.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888582/rioGrandeHosteria/habitaciones/bhizkqpcimbxd0ueya1p.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888532/rioGrandeHosteria/habitaciones/yiz2izamb2zd28gjlfox.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888607/rioGrandeHosteria/habitaciones/eypawbckvvbhahrqzqs1.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888605/rioGrandeHosteria/habitaciones/c7mi55gf6bahgwbhcwe9.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888740/rioGrandeHosteria/habitaciones/uqieb9xn1pyqmykbgkwy.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888013/rioGrandeHosteria/habitaciones/cgzx33yeva1xpln55sm4.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888012/rioGrandeHosteria/habitaciones/xl6cbr8fnmhdyezx2ljw.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717886011/rioGrandeHosteria/habitaciones/xkrl3jbbg0ajdydjocxs.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888011/rioGrandeHosteria/habitaciones/mvjcg1g3sscdgn0xzsai.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888012/rioGrandeHosteria/habitaciones/xqvi0rcnmnooi2diurli.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888010/rioGrandeHosteria/habitaciones/okqo0dctlmlrwshcjxhz.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717887291/rioGrandeHosteria/habitaciones/zrfsvcqs3ehf6dluisz5.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717886018/rioGrandeHosteria/habitaciones/fr4hdktkvfvtm4zb72pd.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888011/rioGrandeHosteria/habitaciones/zbruhfyrm6wz3jsjnhtm.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717886005/rioGrandeHosteria/habitaciones/lzlthcrqubq6ww8vx1gb.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888566/rioGrandeHosteria/habitaciones/rxxvnnfibkzlu6dplvf2.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360996/rioGrandeHosteria/habitaciones/DSC00289_1_ykbhxu.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360992/rioGrandeHosteria/habitaciones/DSC03033_1_qqmgfg.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360989/rioGrandeHosteria/habitaciones/DSC03037_1_wesxe9.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360899/rioGrandeHosteria/habitaciones/DSC00292_ghgcel.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360888/rioGrandeHosteria/habitaciones/DSC02982_xdw017.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360876/rioGrandeHosteria/habitaciones/DSC03035_fcvt01.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360868/rioGrandeHosteria/habitaciones/DSC00285_p0i73w.jpg',
  ];
  const recreativeAreas: string[] = [
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888339/rioGrandeHosteria/complejo/c70jgqroawzxgr8z8mot.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360716/rioGrandeHosteria/area_comun/DSC02956_jpifpy.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360638/rioGrandeHosteria/area_comun/DSC00246_nm0abf.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360642/rioGrandeHosteria/area_comun/DSC00300_jjxyws.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360650/rioGrandeHosteria/area_comun/DSC00302_zkwqdn.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684361056/rioGrandeHosteria/pileta/DSC02977_e1moei.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684625791/rioGrandeHosteria/pileta/pileta_c3sdgd.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360698/rioGrandeHosteria/area_comun/DSC00321_bg49wy.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360730/rioGrandeHosteria/area_comun/DSC03004_ewg3zo.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360732/rioGrandeHosteria/area_comun/DSC02992_sewltz.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1717888159/rioGrandeHosteria/complejo/upjroyuf9vluucz7rgov.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360664/rioGrandeHosteria/area_comun/DSC00306_b3sk3g.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684360732/rioGrandeHosteria/area_comun/IMG_20201208_200934_v0oibb.jpg',
    'https://res.cloudinary.com/dgwdvgu1g/image/upload/w_1200/q_auto/f_auto/v1684361054/rioGrandeHosteria/pileta/DSC00202_p8pikx.jpg',
  ];

  return (
    <Box
      m="auto"
      mb={{ base: '.5rem', md: '1rem' }}
      width={{ base: '95%', sm: '95%', lg: '90%', xl: '90%' }}
      h="fit-content"
    >
      <Tabs isFitted variant="unstyled">
        <TabList>
          <Tab
            color="#c29a5c"
            fontFamily="EB Garamond"
            fontWeight="bold"
            fontSize={{ base: '1rem', sm: '1.2rem', lg: '1.5rem' }}
            _selected={{
              color: 'white',
              bg: '#c29a5c',
            }}
            onClick={() => setSelectedTabId(1)}
          >
            El Complejo
          </Tab>
          <Tab
            color="#c29a5c"
            fontFamily="EB Garamond"
            fontWeight="bold"
            fontSize={{ base: '1rem', sm: '1.2rem', lg: '1.5rem' }}
            _selected={{
              color: 'white',
              bg: '#c29a5c',
            }}
            onClick={() => setSelectedTabId(2)}
          >
            Habitaciones y cabañas
          </Tab>
          <Tab
            color="#c29a5c"
            fontFamily="EB Garamond"
            fontWeight="bold"
            fontSize={{ base: '1rem', sm: '1.2rem', lg: '1.5rem' }}
            _selected={{
              color: 'white',
              bg: '#c29a5c',
            }}
            onClick={() => setSelectedTabId(3)}
          >
            Areas Recreativas
          </Tab>
        </TabList>
        <TabPanels pt="4px">
          <TabPanel p="0" h="85vh">
            <PhotoGallery
              tabId={1}
              selectedTabId={selectedTabId}
              content={theComplex}
              open={open}
              setOpen={setOpen}
            />
          </TabPanel>
          <TabPanel p="0" h="85vh">
            <PhotoGallery
              tabId={2}
              selectedTabId={selectedTabId}
              content={bedrooms}
              open={open}
              setOpen={setOpen}
            />
          </TabPanel>
          <TabPanel p="0" h="85vh">
            <PhotoGallery
              tabId={3}
              selectedTabId={selectedTabId}
              content={recreativeAreas}
              open={open}
              setOpen={setOpen}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Box w="100%" p="1rem" display="flex">
        <Button
          m="auto"
          onClick={() => setOpen(true)}
          textDecoration="none"
          mt={{ base: '1rem', xl: '1.5rem' }}
          borderRadius="0px"
          border="1px solid #c29a5c"
          bg="transparent"
          fontFamily="EB Garamond"
          fontSize="1.3rem"
          fontWeight="600"
          color="#c29a5c"
          px="1.5rem"
          py="0.3rem"
          textAlign="center"
          w="50%"
          _hover={{
            color: '#fff',
            bg: '#c29a5c',
          }}
          _focus={{
            outline: 'none',
          }}
          _active={{
            bg: '#c29a5c',
          }}
        >
          Ver Mas...
        </Button>
      </Box>
    </Box>
  );
};
