import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import About from '../about/About';
import Contact from '../contact/Contact';
import Gallery from '../gallery/Gallery';
import Hero from '../hero/Hero';

const Home: React.FC = () => (
  <>
    <Header />
    <Hero />
    <About />
    {/* <Services /> */}
    <Gallery />
    <Contact />
    <Footer />
  </>
);

export default Home;
