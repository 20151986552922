import { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Link,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { CgCloseR } from 'react-icons/cg';
import Logo from '../../assets/svgs/RioGrandeLogo';

const Links = ['Inicio', 'Nosotros', 'Galeria', 'Contacto'];

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [top, setTop] = useState('0');
  const [start, setStart] = useState(window.pageYOffset);
  useEffect(() => {
    window.onscroll = () => {
      const scroll = window.pageYOffset;
      if (start >= scroll) {
        setTop('0');
      } else {
        setTop('-100');
      }
      setStart(scroll);
    };
  }, [start, top]);

  return (
    <Box
      bg="#fff"
      px={10}
      position="fixed"
      top={top}
      left={0}
      width="100%"
      boxShadow="1px 1px 3px #000"
      zIndex={3}
      transition="top 0.5s"
    >
      <Flex h={20} alignItems="center" justifyContent="space-between">
        <IconButton
          size="md"
          icon={isOpen ? <CgCloseR /> : <GiHamburgerMenu />}
          aria-label="Open Menu"
          fontSize="24px"
          bg="Transparent"
          justifyContent="center"
          display={{ base: 'flex', md: 'none' }}
          onClick={isOpen ? onClose : onOpen}
          _focus={{
            outline: 'none',
            bg: 'Transparent',
          }}
          _hover={{
            bg: 'Transparent',
          }}
          _active={{
            bg: 'Transparent',
          }}
        />
        <HStack
          spacing={8}
          alignItems="center"
          w={{ md: '100%' }}
          justifyContent="space-between"
        >
          <Box>
            <Logo width={'140px'} />
          </Box>
          <HStack as="nav" spacing={4} display={{ base: 'none', md: 'flex' }}>
            {Links.map((link) => (
              <Link
                key={link}
                px={2}
                py={1}
                rounded="md"
                fontSize="1.2rem"
                _hover={{
                  textDecoration: 'none',
                  color: '#AC7F2C',
                  textShadow: '0 0 3px #AC7F2C',
                }}
                _focus={{
                  outline: 'none',
                }}
                href={`#${link}`}
              >
                {link}
              </Link>
            ))}
          </HStack>
        </HStack>
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as="nav" spacing={4}>
            {Links.map((link) => (
              <Link
                key={link}
                px={2}
                py={1}
                rounded="md"
                _hover={{
                  textDecoration: 'none',
                  bg: 'gray.200',
                }}
                _focus={{
                  outline: 'none',
                }}
                href={`#${link}`}
              >
                {link}
              </Link>
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};
export default Header;
